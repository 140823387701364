export const areaOptions = [{
        "id": "440100",
        "parentId": "440000",
        "areaSort": null,
        "children": [{
            "id": "440103000",
            "parentId": "440100",
            "areaSort": null,

            "title": "荔湾区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440104000",
            "parentId": "440100",
            "areaSort": null,

            "title": "越秀区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440105000",
            "parentId": "440100",
            "areaSort": null,

            "title": "海珠区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440106000",
            "parentId": "440100",
            "areaSort": null,

            "title": "天河区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440111000",
            "parentId": "440100",
            "areaSort": null,

            "title": "白云区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440112000",
            "parentId": "440100",
            "areaSort": null,

            "title": "黄埔区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440113000",
            "parentId": "440100",
            "areaSort": null,

            "title": "番禺区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440114000",
            "parentId": "440100",
            "areaSort": null,

            "title": "花都区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440115000",
            "parentId": "440100",
            "areaSort": null,

            "title": "南沙区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440117000",
            "parentId": "440100",
            "areaSort": null,

            "title": "从化区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440118000",
            "parentId": "440100",
            "areaSort": null,

            "title": "增城区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          }
        ],
        "title": "广州市",
        "state": null,
        "type": null,
        "level": 2,
        "label": null,
        "sort": null,
        "isLock": false
      },
      {
        "id": "440200",
        "parentId": "440000",
        "areaSort": null,
        "children": [{
            "id": "440203000",
            "parentId": "440200",
            "areaSort": null,

            "title": "武江区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440204000",
            "parentId": "440200",
            "areaSort": null,

            "title": "浈江区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440205000",
            "parentId": "440200",
            "areaSort": null,

            "title": "曲江区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440222000",
            "parentId": "440200",
            "areaSort": null,

            "title": "始兴县",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440224000",
            "parentId": "440200",
            "areaSort": null,

            "title": "仁化县",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440229000",
            "parentId": "440200",
            "areaSort": null,

            "title": "翁源县",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440232000",
            "parentId": "440200",
            "areaSort": null,

            "title": "乳源瑶族自治县",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440233000",
            "parentId": "440200",
            "areaSort": null,

            "title": "新丰县",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440281000",
            "parentId": "440200",
            "areaSort": null,

            "title": "乐昌市",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440282000",
            "parentId": "440200",
            "areaSort": null,

            "title": "南雄市",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          }
        ],
        "title": "韶关市",
        "state": null,
        "type": null,
        "level": 2,
        "label": null,
        "sort": null,
        "isLock": false
      },
      {
        "id": "440300",
        "parentId": "440000",
        "areaSort": null,
        "children": [{
            "id": "440303000",
            "parentId": "440300",
            "areaSort": null,

            "title": "罗湖区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440304000",
            "parentId": "440300",
            "areaSort": null,

            "title": "福田区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440305000",
            "parentId": "440300",
            "areaSort": null,

            "title": "南山区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440306000",
            "parentId": "440300",
            "areaSort": null,

            "title": "宝安区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440307000",
            "parentId": "440300",
            "areaSort": null,

            "title": "龙岗区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440308000",
            "parentId": "440300",
            "areaSort": null,

            "title": "盐田区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440309000",
            "parentId": "440300",
            "areaSort": null,

            "title": "龙华区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440310000",
            "parentId": "440300",
            "areaSort": null,

            "title": "坪山区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440311000",
            "parentId": "440300",
            "areaSort": null,

            "title": "光明区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          }
        ],
        "title": "深圳市",
        "state": null,
        "type": null,
        "level": 2,
        "label": null,
        "sort": null,
        "isLock": false
      },
      {
        "id": "440400",
        "parentId": "440000",
        "areaSort": null,
        "children": [{
            "id": "440402000",
            "parentId": "440400",
            "areaSort": null,

            "title": "香洲区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440403000",
            "parentId": "440400",
            "areaSort": null,

            "title": "斗门区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440404000",
            "parentId": "440400",
            "areaSort": null,

            "title": "金湾区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          }
        ],
        "title": "珠海市",
        "state": null,
        "type": null,
        "level": 2,
        "label": null,
        "sort": null,
        "isLock": false
      },
      {
        "id": "440500",
        "parentId": "440000",
        "areaSort": null,
        "children": [{
            "id": "440507000",
            "parentId": "440500",
            "areaSort": null,

            "title": "龙湖区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440511000",
            "parentId": "440500",
            "areaSort": null,

            "title": "金平区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440512000",
            "parentId": "440500",
            "areaSort": null,

            "title": "濠江区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440513000",
            "parentId": "440500",
            "areaSort": null,

            "title": "潮阳区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440514000",
            "parentId": "440500",
            "areaSort": null,

            "title": "潮南区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440515000",
            "parentId": "440500",
            "areaSort": null,

            "title": "澄海区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440523000",
            "parentId": "440500",
            "areaSort": null,

            "title": "南澳县",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          }
        ],
        "title": "汕头市",
        "state": null,
        "type": null,
        "level": 2,
        "label": null,
        "sort": null,
        "isLock": false
      },
      {
        "id": "440600",
        "parentId": "440000",
        "areaSort": null,
        "children": [{
            "id": "440604000",
            "parentId": "440600",
            "areaSort": null,

            "title": "禅城区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440605000",
            "parentId": "440600",
            "areaSort": null,

            "title": "南海区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440606000",
            "parentId": "440600",
            "areaSort": null,

            "title": "顺德区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440607000",
            "parentId": "440600",
            "areaSort": null,

            "title": "三水区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440608000",
            "parentId": "440600",
            "areaSort": null,

            "title": "高明区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          }
        ],
        "title": "佛山市",
        "state": null,
        "type": null,
        "level": 2,
        "label": null,
        "sort": null,
        "isLock": false
      },
      {
        "id": "440700",
        "parentId": "440000",
        "areaSort": null,
        "children": [{
            "id": "440703000",
            "parentId": "440700",
            "areaSort": null,

            "title": "蓬江区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440704000",
            "parentId": "440700",
            "areaSort": null,

            "title": "江海区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440705000",
            "parentId": "440700",
            "areaSort": null,

            "title": "新会区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440781000",
            "parentId": "440700",
            "areaSort": null,

            "title": "台山市",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440783000",
            "parentId": "440700",
            "areaSort": null,

            "title": "开平市",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440784000",
            "parentId": "440700",
            "areaSort": null,

            "title": "鹤山市",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440785000",
            "parentId": "440700",
            "areaSort": null,

            "title": "恩平市",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          }
        ],
        "title": "江门市",
        "state": null,
        "type": null,
        "level": 2,
        "label": null,
        "sort": null,
        "isLock": false
      },
      {
        "id": "440800",
        "parentId": "440000",
        "areaSort": null,
        "children": [{
            "id": "440802000",
            "parentId": "440800",
            "areaSort": null,

            "title": "赤坎区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440803000",
            "parentId": "440800",
            "areaSort": null,

            "title": "霞山区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440804000",
            "parentId": "440800",
            "areaSort": null,

            "title": "坡头区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440811000",
            "parentId": "440800",
            "areaSort": null,

            "title": "麻章区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440823000",
            "parentId": "440800",
            "areaSort": null,

            "title": "遂溪县",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440825000",
            "parentId": "440800",
            "areaSort": null,

            "title": "徐闻县",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440881000",
            "parentId": "440800",
            "areaSort": null,

            "title": "廉江市",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440882000",
            "parentId": "440800",
            "areaSort": null,

            "title": "雷州市",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440883000",
            "parentId": "440800",
            "areaSort": null,

            "title": "吴川市",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          }
        ],
        "title": "湛江市",
        "state": null,
        "type": null,
        "level": 2,
        "label": null,
        "sort": null,
        "isLock": false
      },
      {
        "id": "440900",
        "parentId": "440000",
        "areaSort": null,
        "children": [{
            "id": "440902000",
            "parentId": "440900",
            "areaSort": null,

            "title": "茂南区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440904000",
            "parentId": "440900",
            "areaSort": null,

            "title": "电白区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440981000",
            "parentId": "440900",
            "areaSort": null,

            "title": "高州市",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440982000",
            "parentId": "440900",
            "areaSort": null,

            "title": "化州市",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "440983000",
            "parentId": "440900",
            "areaSort": null,

            "title": "信宜市",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          }
        ],
        "title": "茂名市",
        "state": null,
        "type": null,
        "level": 2,
        "label": null,
        "sort": null,
        "isLock": false
      },
      {
        "id": "441200",
        "parentId": "440000",
        "areaSort": null,
        "children": [{
            "id": "441202000",
            "parentId": "441200",
            "areaSort": null,

            "title": "端州区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441203000",
            "parentId": "441200",
            "areaSort": null,

            "title": "鼎湖区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441204000",
            "parentId": "441200",
            "areaSort": null,

            "title": "高要区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441223000",
            "parentId": "441200",
            "areaSort": null,

            "title": "广宁县",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441224000",
            "parentId": "441200",
            "areaSort": null,

            "title": "怀集县",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441225000",
            "parentId": "441200",
            "areaSort": null,

            "title": "封开县",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441226000",
            "parentId": "441200",
            "areaSort": null,

            "title": "德庆县",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441284000",
            "parentId": "441200",
            "areaSort": null,

            "title": "四会市",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          }
        ],
        "title": "肇庆市",
        "state": null,
        "type": null,
        "level": 2,
        "label": null,
        "sort": null,
        "isLock": false
      },
      {
        "id": "441300",
        "parentId": "440000",
        "areaSort": null,
        "children": [{
            "id": "441302000",
            "parentId": "441300",
            "areaSort": null,

            "title": "惠城区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441303000",
            "parentId": "441300",
            "areaSort": null,

            "title": "惠阳区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441322000",
            "parentId": "441300",
            "areaSort": null,

            "title": "博罗县",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441323000",
            "parentId": "441300",
            "areaSort": null,

            "title": "惠东县",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441324000",
            "parentId": "441300",
            "areaSort": null,

            "title": "龙门县",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          }
        ],
        "title": "惠州市",
        "state": null,
        "type": null,
        "level": 2,
        "label": null,
        "sort": null,
        "isLock": false
      },
      {
        "id": "441400",
        "parentId": "440000",
        "areaSort": null,
        "children": [{
            "id": "441402000",
            "parentId": "441400",
            "areaSort": null,

            "title": "梅江区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441403000",
            "parentId": "441400",
            "areaSort": null,

            "title": "梅县区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441422000",
            "parentId": "441400",
            "areaSort": null,

            "title": "大埔县",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441423000",
            "parentId": "441400",
            "areaSort": null,

            "title": "丰顺县",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441424000",
            "parentId": "441400",
            "areaSort": null,

            "title": "五华县",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441426000",
            "parentId": "441400",
            "areaSort": null,

            "title": "平远县",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441427000",
            "parentId": "441400",
            "areaSort": null,

            "title": "蕉岭县",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441481000",
            "parentId": "441400",
            "areaSort": null,

            "title": "兴宁市",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          }
        ],
        "title": "梅州市",
        "state": null,
        "type": null,
        "level": 2,
        "label": null,
        "sort": null,
        "isLock": false
      },
      {
        "id": "441500",
        "parentId": "440000",
        "areaSort": null,
        "children": [{
            "id": "441502000",
            "parentId": "441500",
            "areaSort": null,

            "title": "城区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441521000",
            "parentId": "441500",
            "areaSort": null,

            "title": "海丰县",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441523000",
            "parentId": "441500",
            "areaSort": null,

            "title": "陆河县",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441581000",
            "parentId": "441500",
            "areaSort": null,

            "title": "陆丰市",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          }
        ],
        "title": "汕尾市",
        "state": null,
        "type": null,
        "level": 2,
        "label": null,
        "sort": null,
        "isLock": false
      },
      {
        "id": "441600",
        "parentId": "440000",
        "areaSort": null,
        "children": [{
            "id": "441602000",
            "parentId": "441600",
            "areaSort": null,

            "title": "源城区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441621000",
            "parentId": "441600",
            "areaSort": null,

            "title": "紫金县",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441622000",
            "parentId": "441600",
            "areaSort": null,

            "title": "龙川县",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441623000",
            "parentId": "441600",
            "areaSort": null,

            "title": "连平县",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441624000",
            "parentId": "441600",
            "areaSort": null,

            "title": "和平县",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441625000",
            "parentId": "441600",
            "areaSort": null,

            "title": "东源县",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          }
        ],
        "title": "河源市",
        "state": null,
        "type": null,
        "level": 2,
        "label": null,
        "sort": null,
        "isLock": false
      },
      {
        "id": "441700",
        "parentId": "440000",
        "areaSort": null,
        "children": [{
            "id": "441702000",
            "parentId": "441700",
            "areaSort": null,

            "title": "江城区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441704000",
            "parentId": "441700",
            "areaSort": null,

            "title": "阳东区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441721000",
            "parentId": "441700",
            "areaSort": null,

            "title": "阳西县",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441781000",
            "parentId": "441700",
            "areaSort": null,

            "title": "阳春市",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          }
        ],
        "title": "阳江市",
        "state": null,
        "type": null,
        "level": 2,
        "label": null,
        "sort": null,
        "isLock": false
      },
      {
        "id": "441800",
        "parentId": "440000",
        "areaSort": null,
        "children": [{
            "id": "441802000",
            "parentId": "441800",
            "areaSort": null,

            "title": "清城区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441803000",
            "parentId": "441800",
            "areaSort": null,

            "title": "清新区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441821000",
            "parentId": "441800",
            "areaSort": null,

            "title": "佛冈县",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441823000",
            "parentId": "441800",
            "areaSort": null,

            "title": "阳山县",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441825000",
            "parentId": "441800",
            "areaSort": null,

            "title": "连山壮族瑶族自治县",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441826000",
            "parentId": "441800",
            "areaSort": null,

            "title": "连南瑶族自治县",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441881000",
            "parentId": "441800",
            "areaSort": null,

            "title": "英德市",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441882000",
            "parentId": "441800",
            "areaSort": null,

            "title": "连州市",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          }
        ],
        "title": "清远市",
        "state": null,
        "type": null,
        "level": 2,
        "label": null,
        "sort": null,
        "isLock": false
      },
      {
        "id": "441900",
        "parentId": "440000",
        "areaSort": null,
        "children": [{
            "id": "441900003",
            "parentId": "441900",
            "areaSort": null,

            "title": "东城街道",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441900004",
            "parentId": "441900",
            "areaSort": null,

            "title": "南城街道",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441900005",
            "parentId": "441900",
            "areaSort": null,

            "title": "万江街道",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441900006",
            "parentId": "441900",
            "areaSort": null,

            "title": "莞城街道",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441900101",
            "parentId": "441900",
            "areaSort": null,

            "title": "石碣镇",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441900102",
            "parentId": "441900",
            "areaSort": null,

            "title": "石龙镇",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441900103",
            "parentId": "441900",
            "areaSort": null,

            "title": "茶山镇",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441900104",
            "parentId": "441900",
            "areaSort": null,

            "title": "石排镇",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441900105",
            "parentId": "441900",
            "areaSort": null,

            "title": "企石镇",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441900106",
            "parentId": "441900",
            "areaSort": null,

            "title": "横沥镇",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441900107",
            "parentId": "441900",
            "areaSort": null,

            "title": "桥头镇",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441900108",
            "parentId": "441900",
            "areaSort": null,

            "title": "谢岗镇",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441900109",
            "parentId": "441900",
            "areaSort": null,

            "title": "东坑镇",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441900110",
            "parentId": "441900",
            "areaSort": null,

            "title": "常平镇",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441900111",
            "parentId": "441900",
            "areaSort": null,

            "title": "寮步镇",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441900112",
            "parentId": "441900",
            "areaSort": null,

            "title": "樟木头镇",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441900113",
            "parentId": "441900",
            "areaSort": null,

            "title": "大朗镇",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441900114",
            "parentId": "441900",
            "areaSort": null,

            "title": "黄江镇",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441900115",
            "parentId": "441900",
            "areaSort": null,

            "title": "清溪镇",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441900116",
            "parentId": "441900",
            "areaSort": null,

            "title": "塘厦镇",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441900117",
            "parentId": "441900",
            "areaSort": null,

            "title": "凤岗镇",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441900118",
            "parentId": "441900",
            "areaSort": null,

            "title": "大岭山镇",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441900119",
            "parentId": "441900",
            "areaSort": null,

            "title": "长安镇",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441900121",
            "parentId": "441900",
            "areaSort": null,

            "title": "虎门镇",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441900122",
            "parentId": "441900",
            "areaSort": null,

            "title": "厚街镇",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441900123",
            "parentId": "441900",
            "areaSort": null,

            "title": "沙田镇",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441900124",
            "parentId": "441900",
            "areaSort": null,

            "title": "道滘镇",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441900125",
            "parentId": "441900",
            "areaSort": null,

            "title": "洪梅镇",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441900126",
            "parentId": "441900",
            "areaSort": null,

            "title": "麻涌镇",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441900127",
            "parentId": "441900",
            "areaSort": null,

            "title": "望牛墩镇",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441900128",
            "parentId": "441900",
            "areaSort": null,

            "title": "中堂镇",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441900129",
            "parentId": "441900",
            "areaSort": null,

            "title": "高埗镇",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441900401",
            "parentId": "441900",
            "areaSort": null,

            "title": "松山湖",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441900402",
            "parentId": "441900",
            "areaSort": null,

            "title": "东莞港",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "441900403",
            "parentId": "441900",
            "areaSort": null,

            "title": "东莞生态园",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          }
        ],
        "title": "东莞市",
        "state": null,
        "type": null,
        "level": 2,
        "label": null,
        "sort": null,
        "isLock": false
      },
      {
        "id": "442000",
        "parentId": "440000",
        "areaSort": null,
        "children": [{
            "id": "442000000",
            "parentId": "442000",
            "areaSort": null,

            "title": "神湾镇",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "442000001",
            "parentId": "442000",
            "areaSort": null,

            "title": "石岐街道",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "442000002",
            "parentId": "442000",
            "areaSort": null,

            "title": "东区街道",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "442000003",
            "parentId": "442000",
            "areaSort": null,

            "title": "中山港街道",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "442000004",
            "parentId": "442000",
            "areaSort": null,

            "title": "西区街道",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "442000005",
            "parentId": "442000",
            "areaSort": null,

            "title": "南区街道",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "442000006",
            "parentId": "442000",
            "areaSort": null,

            "title": "五桂山街道",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "442000100",
            "parentId": "442000",
            "areaSort": null,

            "title": "小榄镇",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "442000101",
            "parentId": "442000",
            "areaSort": null,

            "title": "黄圃镇",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "442000102",
            "parentId": "442000",
            "areaSort": null,

            "title": "民众镇",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "442000103",
            "parentId": "442000",
            "areaSort": null,

            "title": "东凤镇",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "442000104",
            "parentId": "442000",
            "areaSort": null,

            "title": "东升镇",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "442000105",
            "parentId": "442000",
            "areaSort": null,

            "title": "古镇镇",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "442000106",
            "parentId": "442000",
            "areaSort": null,

            "title": "沙溪镇",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "442000107",
            "parentId": "442000",
            "areaSort": null,

            "title": "坦洲镇",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "442000108",
            "parentId": "442000",
            "areaSort": null,

            "title": "港口镇",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "442000109",
            "parentId": "442000",
            "areaSort": null,

            "title": "三角镇",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "442000110",
            "parentId": "442000",
            "areaSort": null,

            "title": "横栏镇",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "442000111",
            "parentId": "442000",
            "areaSort": null,

            "title": "南头镇",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "442000112",
            "parentId": "442000",
            "areaSort": null,

            "title": "阜沙镇",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "442000113",
            "parentId": "442000",
            "areaSort": null,

            "title": "南朗镇",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "442000114",
            "parentId": "442000",
            "areaSort": null,

            "title": "三乡镇",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "442000115",
            "parentId": "442000",
            "areaSort": null,

            "title": "板芙镇",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "442000116",
            "parentId": "442000",
            "areaSort": null,

            "title": "大涌镇",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          }
        ],
        "title": "中山市",
        "state": null,
        "type": null,
        "level": 2,
        "label": null,
        "sort": null,
        "isLock": false
      },
      {
        "id": "445100",
        "parentId": "440000",
        "areaSort": null,
        "children": [{
            "id": "445102000",
            "parentId": "445100",
            "areaSort": null,

            "title": "湘桥区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "445103000",
            "parentId": "445100",
            "areaSort": null,

            "title": "潮安区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "445122000",
            "parentId": "445100",
            "areaSort": null,

            "title": "饶平县",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          }
        ],
        "title": "潮州市",
        "state": null,
        "type": null,
        "level": 2,
        "label": null,
        "sort": null,
        "isLock": false
      },
      {
        "id": "445200",
        "parentId": "440000",
        "areaSort": null,
        "children": [{
            "id": "445202000",
            "parentId": "445200",
            "areaSort": null,

            "title": "榕城区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "445203000",
            "parentId": "445200",
            "areaSort": null,

            "title": "揭东区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "445222000",
            "parentId": "445200",
            "areaSort": null,

            "title": "揭西县",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "445224000",
            "parentId": "445200",
            "areaSort": null,

            "title": "惠来县",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "445281000",
            "parentId": "445200",
            "areaSort": null,

            "title": "普宁市",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          }
        ],
        "title": "揭阳市",
        "state": null,
        "type": null,
        "level": 2,
        "label": null,
        "sort": null,
        "isLock": false
      },
      {
        "id": "445300",
        "parentId": "440000",
        "areaSort": null,
        "children": [{
            "id": "445302000",
            "parentId": "445300",
            "areaSort": null,

            "title": "云城区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "445303000",
            "parentId": "445300",
            "areaSort": null,

            "title": "云安区",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "445321000",
            "parentId": "445300",
            "areaSort": null,

            "title": "新兴县",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "445322000",
            "parentId": "445300",
            "areaSort": null,

            "title": "郁南县",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          },
          {
            "id": "445381000",
            "parentId": "445300",
            "areaSort": null,

            "title": "罗定市",
            "state": null,
            "type": null,
            "level": 3,
            "label": null,
            "sort": null,
            "isLock": false
          }
        ],
        "title": "云浮市",
        "state": null,
        "type": null,
        "level": 2,
        "label": null,
        "sort": null,
        "isLock": false
      }
    
]
