<template>
  <div class="join_container">
    <div class="content_box">
      <div class="title">欢迎入驻LD驾考</div>
      <div class="tips">请确保信息真实有效，不得冒用他人身份或驾校资质，一经发现将承担所有法律责任.</div>
      <div class="form_box">
        <div class="form_main">
          <el-form :model="joinFrom" ref="joinFrom" :rules="rules">
            <!-- 城市 -->
            <el-form-item style="text-align:left" prop="city" label="城市:" label-width="120px">
              <el-cascader v-model="joinFrom.areas" placeholder="请选择城市" :options="areaOptions" @change="handleChange"
                style="width:500px"
                :props="{ multiple: false,value: 'id',label: 'title',children: 'children',emitPath: false}">
              </el-cascader>
            </el-form-item>
            <!-- 类型 -->
            <el-form-item style="text-align:left" prop="type" label="类型:" label-width="120px">
              <el-radio-group v-model="joinFrom.type">
                <el-radio :label="1">自主训练场驾校</el-radio>
                <el-radio :label="2">个人门店驾校</el-radio>
              </el-radio-group>
            </el-form-item>
            <!-- 驾校简称 -->
            <el-form-item prop="schoolName" label="驾校简称:" label-width="120px">
              <el-input placeholder="请输入驾校简称" v-model="joinFrom.schoolName" style="width:500px">
              </el-input>
            </el-form-item>
            <!-- 驾校全称 -->
            <el-form-item prop="schoolAllName" label="驾校全称:" label-width="120px">
              <el-input placeholder="请输入驾校全称" v-model="joinFrom.schoolAllName" style="width:500px">
              </el-input>
            </el-form-item>
            <!-- 联系人 -->
            <el-form-item prop="linkPerson" label="联系人:" label-width="120px">
              <el-input placeholder="请输入联系人" v-model="joinFrom.linkPerson" style="width:500px">
              </el-input>
            </el-form-item>
            <!-- 联系手机 -->
            <el-form-item prop="phone" label="联系手机:" label-width="120px">
              <el-input placeholder="请输入联系手机" v-model="joinFrom.phone" style="width:500px">
              </el-input>
            </el-form-item>
            <div style="color:#FCCA00;margin-bottom:20px">信息提交后，1~3个工作日内会有工作人员与您联系，请注意接听!</div>
            <el-form-item>
              <el-button type="primary" size="mini" style="width:130px">提交</el-button>
            </el-form-item>
            <el-form-item>
              <span>点击提交即视为同意LD驾考的<span style="color:#1a73e8;cursor:pointer"
                  @click="showAgreement">《用户使用协议》</span>和<span style="color:#1a73e8;cursor:pointer"
                  @click="showPolicy">《隐私政策》</span>。</span>
            </el-form-item>
          </el-form>
        </div>
        <!--遮罩层-->
        <div class="mask" v-if="agreement || policy" @click="closeAgreement"></div>
        <!--协议弹出框-采购商协议-->
        <div class="agreement_box" v-if="agreement || policy">
          <div class="agreement_header">
            <div class="agreement_title">{{title}}</div>
            <div class="close_btn" @click="closeAgreement">×</div>
          </div>
          <div class="agreement_body">
            <div class="home_guide">
              <p v-html="htmlText"></p>
            </div>
          </div>
          <div class="footer">
            <div class="agree_close" @click="closeAgreement">确认关闭</div>
          </div>
        </div>


      </div>
    </div>


  </div>
</template>

<script>
  import {
    areaOptions
  } from './data/area'
  import {
    agreementText
  } from './data/agreement'
  import {
    policyText
  } from './data/policy'
  export default {
    name: 'WorkspaceJsonJoinld',

    data() {
      return {
        joinFrom: {
          areas: [], //城市
          type: '',
          schoolName: '',
          schoolAllName: '',
          linkPerson: '',
          phone: ''
        },
        rules: {
          city: [{
            required: true,
            message: '请选择城市',
            trigger: 'change'
          }],
          type: [{
            required: true,
            message: '请选择类型',
            trigger: 'change'
          }],
          schoolName: [{
            required: true,
            message: '请输入驾校简称',
            trigger: 'blur'
          }],
          schoolAllName: [{
            required: true,
            message: '请输入驾校全称',
            trigger: 'blur'
          }],
          linkPerson: [{
            required: true,
            message: '请输入联系人',
            trigger: 'blur'
          }],
          phone: [{
            required: true,
            message: '请输入联系人',
            trigger: 'blur'
          }],
        },
        areaOptions: areaOptions,
        agreement: false, //用户协议
        policy: false, //隐私政策
        title: '', //弹框title
        htmlText: '', //内容

      };
    },

    mounted() {

    },
    created() {

    },

    methods: {
      handleChange() {

      },
      // 点击用户协议
      showAgreement() {
        this.agreement = true
        this.title = '用户协议'
        this.htmlText = agreementText

      },
      // 点击隐私政策
      showPolicy() {
        this.policy = true
        this.title = '隐私政策'
        this.htmlText = policyText
      },
      //关闭协议或政策
      closeAgreement() {
        this.agreement = false
        this.policy = false
      }

    },
  };

</script>

<style lang="scss" scoped>
  .join_container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    .content_box {
      width: 1200px;
      min-height: 200px;
      box-sizing: border-box;
      padding-top: 30px;

      .title {
        font-size: 30px;
      }

      .tips {
        height: 40px;
        line-height: 40px;
        color: #DE868F
      }

      .form_box {
        width: 800px;
        min-height: 500px;
        background-color: #BBBBBB;
        margin: 0 auto;
        border-radius: 14px;

        .form_main {
          width: 700px;
          min-height: 400px;
          margin: 0 auto;
          box-sizing: border-box;
          padding-top: 20px;
          display: flex;
          justify-content: center;
        }

        .mask {
          position: fixed;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 90 !important;
          height: 100%;
          background-color: rgba(0, 0, 0, .45);
        }

        .agreement_box {
          position: absolute;
          top: 50%;
          left: 50%;
          -ms-transform: translate(-50%, -50%);
          -moz-transform: translate(-50%, -50%);
          -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          background-color: #FFFFFF;
          box-sizing: border-box;
          width: 750px;
          padding: 10px 10px 10px 10px;
          z-index: 999 !important;

          .agreement_header {
            height: 40px;
            line-height: 40px;
            font-size: 17px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            border-bottom: 1px solid #ebe2e2;

            .close_btn {
              width: 30px;
              height: 30px;
              font-size: 30px;
              color: #888888;
              cursor: pointer;
            }

          }

          .agreement_body {
            height: 500px;
            padding: 30px 40px;
            overflow-y: scroll;
            margin-top: 20px;
            margin-bottom: 20px;
            text-align: left;
          }

          .footer {
            height: 50px;
            box-sizing: border-box;
            border-top: 1px solid #ebe2e2;
            display: flex;
            flex-direction: row;
            justify-content: center;

            .agree_close {
              width: 120px;
              height: 35px;
              background-color: red;
              text-align: center;
              line-height: 35px;
              font-size: 14px;
              color: #FFFFFF;
              margin: 12px 0;
              cursor: pointer;
            }
          }
        }

      }
    }
  }

</style>
